.header {
  margin: 0 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-title {
  font-size: 32px;
  font-weight: 500;
}
.exercise {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.middle-landscape {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.time {
  font-size: 100px;
  font-weight: 500;
  margin: 0;
}
.title {
  font-size: 64px;
  font-weight: 400;
  margin: 0;
}
.middle-landscape .time {
  font-size: 80px;
  font-weight: 500;
}
.middle-landscape .title {
  font-size: 50px;
  font-weight: 400;
  margin: 0;
}
.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}

@media (max-width: 480px) {
  .header-title {
    font-size: 32px;
    font-weight: 500;
  }
  .time {
    font-size: 80px;
    font-weight: 500;
    margin: 0;
  }
  .title {
    font-size: 44px;
    font-weight: 400;
    margin: 0;
  }
}

@media (max-width: 400px) {
  .header-title {
    font-size: 32px;
    font-weight: 500;
  }
}

@media (max-width: 370px) {
  .header-title {
    font-size: 28px;
    font-weight: 400;
  }
}

@media (max-width: 290px) {
  .header-title {
    font-size: 18px;
    font-weight: 200;
  }
}
