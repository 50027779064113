button {
  position: relative;
  border: 1.5px solid;
  background-color: none;
  background: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: large;
  font-weight: 600;
}

button:active {
  top: 1px;
}

button.light {
  border-color: #ffffff;
  color: #ffffff;
}

button.light:hover {
  background-color: #ffffff;
  color: black;
}

button.dark {
  border-color: black;
  color: black;
}

button.dark:hover {
  background-color: black;
  color: #ffffff;
}
