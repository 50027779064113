.top {
  z-index: 100;
  margin-bottom: 10rem;
}

.end-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
