.icon {
  position: relative;
  margin: 1rem;
  border: 1px solid transparent;
  padding: 0.25rem;
  transition: 0.1s;
}
.icon:hover {
  border-radius: 8px;
}

@media (any-hover: hover) {
  .black:hover {
    border: 1px solid black;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
  }
  .white:hover {
    border: 1px solid white;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
  }
}

.black:active {
  border: 1px solid black;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
}
.white:active {
  border: 1px solid white;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
}

.icon:active {
  top: 1px;
  left: 1px;
  box-shadow: none;
}

@media (max-width: 400px) {
  .icon {
    margin: 0.25rem;
  }
}
