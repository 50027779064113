:root {
  --dark-blue: #1d2b53;
  --maroon: #7e2553;
  --dark-green: #008751;
  --brown: #ab5236;
  --dark-grey: #5f574f;
  --light-grey: #c2c3c7;
  --white: #fff1e8;
  --red: #ff004d;
  --orange: #ffa300;
  --yellow: #ffec27;
  --light-green: #00e436;
  --light-blue: #29adff;
  --purple: #83769c;
  --pink: #ff77a8;
  --baige: #ffccaa;
}
