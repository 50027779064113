.start-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.start-container h1 {
  font-size: 100px;
  margin: 1rem;
}

.start-container #start-button {
  margin-bottom: 10rem;
  font-size: 50px;
}

#start-button:hover {
  background-color: white;
  color: #ff004d;
}

@media (max-width: 1140px) {
  .start-container h1 {
    font-size: 80px;
  }
  .start-container #start-button {
    font-size: 40px;
  }
}

@media (max-width: 900px) {
  .start-container h1 {
    font-size: 60px;
  }
  .start-container #start-button {
    font-size: 30px;
  }
}

@media (max-width: 700px) {
  .start-container h1 {
    font-size: 40px;
  }
  .start-container #start-button {
    font-size: 25px;
  }
}

@media (max-width: 460px) {
  .start-container h1 {
    font-size: 35px;
  }
}

@media (max-width: 400px) {
  .start-container h1 {
    font-size: 30px;
  }
}
